import React from "react";

import { navigate } from "gatsby-link";
import styled from "styled-components";

import BackLink from "../components/Buttons/BackLink";
import ButtonLinkGhost from "../components/Buttons/ButtonLinkGhost";
import ButtonLink from "../components/Buttons/ButtonLink";
import Icon from "../components/svg";
import Feefo from "../images/feefo.png";
import SiteFooter from "../components/Report/SiteFooter";

import { useJourneyContext } from "../components/Contexts/JourneyContext/JourneyContext";

function SolutionsInfo({ pageContext }) {
  let story = pageContext.story;
  const journeyContext = useJourneyContext();

  return (
    <div>
      <div className="d-md-none">
        <div className="container">
          <StyledBackLink
            className="ml-0"
            tabIndex="0"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <Panel
        className="bg-grey pb-3 d-none d-md-block"
        story={pageContext.solution.story ? "true" : "false"}
        image={`../../../banner-${pageContext.solution.name}.png`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 pb-5">
              <StyledBackLink
                className="ml-0"
                tabIndex="0"
                onClick={() => navigate(-1)}
              />
              <h2 className="font-weight-bold mt-4">
                With{" "}
                {pageContext.solution.abbreviation.charAt(0) === "I"
                  ? "an"
                  : "a"}{" "}
                {pageContext.solution.displayName}
              </h2>
              <p className="font-weight-bold my-3">
                {pageContext.solution?.prosTitle}
              </p>
              <ul className="list-unstyled">
                {pageContext.solution?.pros?.map((pro) => (
                  <li
                    key={`${pageContext.solution.name}-pro-${pro.order}`}
                    className="d-flex mb-2"
                  >
                    <Icon className="pt-1" name="tick" />
                    <span className="ml-2">{pro.value}</span>
                  </li>
                ))}
              </ul>
              <p className="font-weight-bold my-3">
                {pageContext.solution?.consTitle}
              </p>
              <ul className="list-unstyled">
                {pageContext.solution?.cons?.map((con) => (
                  <li
                    key={`${pageContext.solution.name}-con-${con.order}`}
                    className="d-flex mb-2"
                  >
                    <Icon className="pt-1" name="cross" />
                    <span className="ml-2">{con.value}</span>
                  </li>
                ))}
              </ul>
            </div>
            {pageContext.solution.story ? (
              <div className="col-md-6 pt-5">
                <div className="bg-white shadow text-center p-4 rounded">
                  <StoryImageWrap className="mx-auto d-flex">
                    <img
                      width="90"
                      height="90"
                      className="align-self-center mx-auto"
                      src={`../../../${story.image}.png`}
                      alt="Story"
                    ></img>
                  </StoryImageWrap>
                  <p className="mt-3 px-md-5 font-weight-medium">
                    {story.summary}
                  </p>
                  <ButtonLinkGhost
                    href={`/stories/${pageContext.solution?.name}`}
                    type="button"
                    className="btn d-block mx-auto mt-4 font-weight-medium"
                  >
                    Read {story.displayName}'s story
                  </ButtonLinkGhost>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Panel>

      <div className="container py-4 py-md-5">
        <h2 className="pb-4 font-weight-bold d-none d-md-block">
          What happens {pageContext.solution.article}{" "}
          {pageContext.solution.abbreviation} ?
        </h2>
        <h4 className="pb-4 font-weight-bold d-md-none">
          What happens {pageContext.solution.article}{" "}
          {pageContext.solution.abbreviation} ?
        </h4>
        <div className="row justify-content-center">
          {pageContext.solution?.whatHappens?.map((whatHappens) => (
            <Col
              key={`${pageContext.solution.name}-con-${whatHappens.order}`}
              className="offset-1 col-10 offset-md-0 col-md-6 mb-4 d-flex"
            >
              <div className="bg-white fwg-box-shadow text-center p-4 rounded">
                <Icon name={whatHappens.icon} />
                <p className="text-medium font-weight-bold line-height-reduced mt-2">
                  {whatHappens.heading}
                </p>
                <P className="mt-3 font-weight-medium line-height-reduced">
                  {whatHappens.title}
                </P>
              </div>
            </Col>
          ))}
        </div>
      </div>
      {pageContext.solution.story ? (
        <div className="container d-md-none">
          <div className="bg-white text-center">
            <img
              src={Feefo}
              alt="feefo platinum service 2021"
              className="pb-4"
            />
            <h4 className="font-weight-bold pb-4 mb-3">
              Real stories from <br />
              Feefo reviews
            </h4>
            <div className="shadow p-4 rounded">
              <StoryImageWrap className="mx-auto d-flex shadow">
                <img
                  width="90"
                  height="90"
                  className="align-self-center mx-auto"
                  src={`../../../${story.image}.png`}
                  alt="Story"
                ></img>
              </StoryImageWrap>
              <p className="mt-3 font-weight-medium">{story.summary}</p>
              <ButtonLinkGhost
                href={`/stories/${pageContext.solution?.name}`}
                type="button"
                className="btn d-block mx-auto mt-4 font-weight-medium"
              >
                Read {story.displayName}'s story
              </ButtonLinkGhost>
            </div>
          </div>
        </div>
      ) : null}
      <div className="py-5 bg-grey">
        <div className="container">
          <h2 className="mb-4 pb-2 font-weight-bold d-none d-md-block">
            {pageContext.solution?.howWeWorkTitle}
          </h2>
          <h4 className="mb-4 pb-2 font-weight-bold d-md-none">
            {pageContext.solution?.howWeWorkTitle}
          </h4>
          <ul className="list-unstyled row">
            {pageContext.solution?.howWeWork?.map((howWeWork) => (
              <li
                key={`${pageContext.solution.name}-pro-${howWeWork.order}`}
                className="d-flex mb-4 col-12 col-md-6"
              >
                <Icon className="pt-1" name="tick" />
                <span className="ml-2">{howWeWork.value}</span>
              </li>
            ))}
          </ul>
          <p className="pt-2 ml-4">
            {pageContext.solution?.feesTitle}
            <FeesLink />
            .
          </p>
        </div>
      </div>

      <div className="container py-5 text-center">
        <h4 className="font-weight-bold px-3 px-md-0">
          {pageContext.solution?.contactUsTitle}
        </h4>
        <ButtonLink
          onClick={() => window.open('tel:' + journeyContext?.source?.phoneNo)}
          className="btn d-block mx-auto mt-4"
        >
          Get Started
        </ButtonLink>
      </div>
      <SiteFooter footerTandC={pageContext.solution.footerTandC} />
    </div>
  );
}

function FeesLink() {
  const journeyContext = useJourneyContext();

  return (
    <a
      className="text-dark"
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(journeyContext?.journey?.meta?.feesURL)}
      target="_blank"
      rel="noreferrer"
    >
      <u>here</u>
    </a>
  );
} 

const StyledBackLink = styled(BackLink)`
  margin: 10px 0px 0px 0px;
  margin-top: 20px;
  @media (min-width: 767px) {
    margin: 10px 0px 0px 15px;
  }
`;

const Col = styled.div`
  @media (min-width: 768px) {
    &:last-child:not(:nth-child(even)) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const P = styled.div`
  white-space: pre-line;
`;
const StoryImageWrap = styled.div`
  border: 2px solid ${(props) => props.theme.colors.blueDark};
  border-radius: 63px;
  height: 110px;
  width: 110px;
`;

const Panel = styled.div`
  position: relative;

  @media (min-width: 768px) {
    min-height: 393px;

    &::before {
      ${(props) =>
        props.story === "false" &&
        `
    display: block; 
    content: "";
    width: 50%;
    position:absolute;
    height: 100%;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    `}
      background-image: ${(props) =>
        props.story === "false" ? `url(${props.image})` : null};
    }
  }
  p {
    font-sizee: 18px;
  }
`;

export default SolutionsInfo;
